.plus-btn{
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  background-color: #005086;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}