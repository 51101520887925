@font-face {
    font-family: Poppins;
    src: url(./poppins/Poppins-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(./poppins/Poppins-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(./poppins/Poppins-Light.otf) format('opentype');
    font-weight: light;
    font-style: normal;
}